import React from "react";

import Layout from "../../components/layout";
import {ApiDocSidebar} from "../../components/sidebar";
import {KeyHeader, KeyNestedHeader} from "../../components/typography";

const pageTitle = "Build Library";

const Option = ({option, children}) => (
    <>
        <code>{option}</code>{' - '}
        {children}
    </>
);

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<ApiDocSidebar/>}
            mobileNav={false}
    >
        <KeyHeader className="mt-0">Instructions for Compiling SQLAPI++</KeyHeader>

        <p>
            Generally SQLAPI++ ships with some libraries already compiled. However we always recommend
            you recompile SQLAPI++ with the C++ compiler you use for your project/application.
            You should use appropriate batch file located in the src subdirectory of SQLAPI++ distribution
            (sqlapi_msvc.bat, sqlapi_bcc.bat, sqlapi_mingw.bat, sqlapi_gcc, sqlapi_ss, ...).
            These files also allow to modify SQLAPI++ build options. Review the batch before you execute
            it - you may need to configure compiler environment and SQLAPI++ build options.
        </p>
        <p>
            Build options should be passed on the <strong>make</strong> tool command line.
        </p>

        <KeyNestedHeader>General build options</KeyNestedHeader>
        <ul>
            <li>
                <Option option="CFG">
                    specify what type of the configuration should be build (possible values: release, debug)
                </Option>
            </li>
            <li>
                <Option option="CFG_LINK">
                    specify the build procedure output type (possible values: dynamic, static)
                </Option>
            </li>
            <li>
                <Option option="SA_DEFINE">always use SA_NO_TRIAL to avoid trial exception and messages</Option>
            </li>
            <li>
                <Option option="SQLAPI_OS">
                    should be specified for Linux/Unix platforms only (possible values:
                    SQLAPI_LINUX (default),
                    SQLAPI_SOLARIS, SQLAPI_AIX, SQLAPI_HPUX, SQLAPI_OSX, SQLAPI_SCOOSR5)
                </Option>
            </li>
            <li>
                <Option option="SA_64BIT">should be set if you'd like to build 64-bit SQLAPI++ binaries</Option>
            </li>
            <li>
                <Option option="SA_32BIT">should be set if you'd like to build 32-bit SQLAPI++ binaries</Option>
            </li>
            <li>
                <Option option="SA_BUILD_OPTIONS">can be used for defineing another build options in a separate file.
                    There is also sample SQLAPI/src/BUILD_OPTIONS.Makefile with all possible options decribed.</Option>
            </li>
        </ul>

        <KeyNestedHeader>DBMS support build options</KeyNestedHeader>
        <ul>
            <li><Option option="SA_CLIENT_ALL">build all available DBMS support</Option></li>
            <li><Option option="SA_CLIENT_IBASE">build InterBase/Firebird support</Option></li>
            <li><Option option="SA_CLIENT_ODBC">build ODBC support</Option></li>
            <li><Option option="SA_CLIENT_ORACLE">build Oracle support</Option></li>
            <li><Option option="SA_CLIENT_INFORMIX">build Informix support</Option></li>
            <li><Option option="SA_CLIENT_DB2">build DB2 support</Option></li>
            <li><Option option="SA_CLIENT_SYBASE">build Sybase support</Option></li>
            <li><Option option="SA_CLIENT_SQLBASE">build SQLBase support</Option></li>
            <li><Option option="SA_CLIENT_MYSQL">build MySQL support</Option></li>
            <li><Option option="SA_CLIENT_MARIADB">build MariaDB support</Option></li>
            <li><Option option="SA_CLIENT_PGSQL">build PostgreSQL support</Option></li>
            <li><Option option="SA_CLIENT_SQLITE">build SQLite support</Option></li>
            <li><Option option="SA_CLIENT_CUBESQL">build CubeSQL support</Option></li>
        </ul>

        <KeyNestedHeader>Disable some DBMS API support</KeyNestedHeader>
        <ul>
            <li><Option option="SA_NO_OLEDB">disable MSSQL OLEDB client</Option></li>
            <li><Option option="SA_NO_SQLNCLI">"disable MSSQL SQLNCLI client</Option></li>
        </ul>

        <KeyNestedHeader>Features build options</KeyNestedHeader>
        <ul>
            <li><Option option="SA_UNICODE">"build Unicode library version</Option></li>
            <li>
                <Option option="SA_USE_PTHREAD">
                    build thread safe library (with pthread library, Linux/Unix only)
                </Option>
            </li>
            <li>
                <Option option="SA_DISABLE_ATOMIC_HEADER">
                    Disable &lt;atomic&gt; header using for the thread safe SAString data (Linux/Unix only, &lt;atomic&gt; used with C++11 and above).
                </Option>
            </li>
            <li>
                <Option option="SA_USE_PTHREAD_FOR_ATOMIC">
                    Use SAMutex (pthread based) instead of &lt;atomic&gt; for the thread safe SAString data (Linux/Unix only).
                </Option>
            </li>
            <li>
                <Option option="SA_PARAM_USE_DEFAULT">
                    specify to use default parameter values with procedures (by default they are NULL)
                </Option>
            </li>
            <li><Option option="SA_STRING_EXT">use modern SAString modifications</Option></li>
            <li>
                <Option option="SA_THROW_WRONG_DATA_EXCEPTION">
                    throw SAException when value assigned is not correct
                </Option>
            </li>
        </ul>

        <KeyNestedHeader>Code compiling build options</KeyNestedHeader>
        <ul>
            <li><Option option="SA_USE_PCH">use precompiled headers</Option></li>
            <li><Option option="SA_INCLUDES_SYBASE">specify own Sybase ASE include folder</Option></li>
            <li><Option option="SA_CUBESQL_SRCDIR">specify folder with CubeSQL SDK sources</Option></li>
            <li>
                <Option option="SA_RUNTIME">
                    build the library with static or dynamic LIBC (Borland C++ and Microsoft Visual Studio only,
                    possible values: dynamic, static)
                </Option>
            </li>
        </ul>

        <KeyNestedHeader>Binaries linking build options</KeyNestedHeader>
        <ul>
            <li><Option option="SA_STATIC_INFORMIX">link SQLAPI++ with Informix ODBC/CLI</Option></li>
            <li><Option option="SA_STATIC_DB2">link SQLAPI++ with DB2 CLI library</Option></li>
            <li><Option option="SA_STATIC_ORACLE">link SQLAPI++ with Oracle OCI library</Option></li>
            <li><Option option="SA_STATIC_MYSQL">link SQLAPI++ with MySQL client library</Option></li>
            <li><Option option="SA_STATIC_MARIADB">link SQLAPI++ with MariaDB client library</Option></li>
            <li><Option option="SA_STATIC_SQLITE">link SQLAPI++ with SQLite client library</Option></li>
            <li><Option option="SA_STATIC_SYBASE">link SQLAPI++ with Sybase Open Client library</Option></li>
            <li><Option option="SA_STATIC_PGSQL">link SQLAPI++ with PostgreSQL libpq</Option></li>
            <li><Option option="SA_STATIC_CUBESQL">link SQLAPI++ with CubeSQL sources</Option></li>
            <li><Option option="SA_MANIFEST_EMB">embedded manifest (Microsoft Visual Studio only)</Option></li>
        </ul>
    </Layout>
);

export default Page;
